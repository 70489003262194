/** @jsx jsx */
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import { Box, jsx } from "theme-ui";
import React, { useCallback } from "react";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";

const GeneralDonationToSmsfUsaMobile = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation SMSF, USA";
    return `<gatsby_donation donationId = "${id}" btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation - SMSF, USA"/>`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation_SMSF,_USA", event),
    []
  );

  return (
    <DonationLayout
      seoData={{
        title: "General Donation to SMSF, USA [Mobile] - Donations",
      }}
    >
      <Box>
        <h3 sx={{ mb: 3, mt: 3, textAlign: "center" }}>
          General Donation to SMSF, USA
        </h3>
        <PageContent
          eventCallback={eventCallback}
          pageContent={pageContent(16, "Donate Now")}
        />
      </Box>
    </DonationLayout>
  );
};

export default GeneralDonationToSmsfUsaMobile;
